import { faBars, faComment, faPhone, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import getGeneralData from '../../data/general';
import getNavItems from '../../data/navItems';
import { deviceSize } from '../../helpers/device';
import { Image } from '../Image';
import { Link } from '../Link';
import stylesFunction, { Tab } from './styles';

export const Nav = (props) => {
  
  const [hoveringId, setHoveringId] = useState(-1);
  const [currentIntervalTick, setCurrentIntervalTick] = useState(-1);

  useEffect(() => {
    let count = -1
    const interval = setInterval(() => {
      count++
      setCurrentIntervalTick(count);
    }, 100);
    return () => clearInterval(interval);
  }, [hoveringId])

  function setHoverId(index){
    setHoveringId(index);
    setCurrentIntervalTick(-1);
  }

  function toggleMobileMenu(){
    if(hoveringId!=='mobile'){
      setHoverId('mobile');
    }else{
      setHoverId(-1);
    }
  }

  const styles = stylesFunction();
  const navItems = getNavItems();
  const device = deviceSize();
  const generalData = getGeneralData();

  return (
    <>
      <div style={styles.navHeight}>
        <div style={{...styles.navBarContainer, ...styles.navHeight}}>

          {/* Mobile Phone Button */}
          {device !== 'desktop' && 
            <div>
              <Link href={`tel:${generalData.contact.phone}`} external>
                <FontAwesomeIcon icon={faPhone} style={styles.phone} color='white' />
              </Link>

              <Link data-calltrk-noswap href={`sms:+:${generalData.contact.phone}`} external>
                <FontAwesomeIcon icon={faComment} style={styles.phone} color='white' />
              </Link>
            </div>
          }

          {/* Logo */}
          <Link href="/">
            <Image style={styles.logo} src={'https://storage.googleapis.com/smiledentistdotcomassets/logo.webp'} />
          </Link>

          {/* Mobile Menu Icon */}
          {device !== 'desktop' && (
            <div style={{ paddingLeft: 35 }}> {/* To center align logo, add left padding */}
              {hoveringId !== 'mobile' && <FontAwesomeIcon style={styles.bars} icon={faBars} color={"white"} onClick={() => toggleMobileMenu()} />}
              {hoveringId === 'mobile' && <FontAwesomeIcon style={{...styles.bars, paddingLeft: 2, paddingRight: 3}} icon={faTimes} color={"white"} onClick={() => toggleMobileMenu()} />}
            </div>
          )}

          {/* Mobile Link Holder */}
          {device !== 'desktop' && <div style={styles.mobileLinkHolder}>
            {/* Links */}
            {navItems.map((link, index) => {
              return (
                <Link href={link.url} key={`a-${index}`} >
                  <div onClick={() => setHoverId(-1)} style={{ ...styles.mobileLink, ...((currentIntervalTick > index - 1 && hoveringId === 'mobile') ? { height: 'auto', transform: `translate(0px, 0px)`, paddingTop: 10, paddingBottom: 10, opacity: 1, zIndex: 1 } : {}) }}>
                    {link.overide || link.nameMobile}
                  </div>
                </Link>
              );
            })}
          </div>}

          {/* Desktop Link Holder */}
          {device === 'desktop' && <div style={styles.linkHolder}>

            {/* Links */}
            {navItems.map((link, index) => {

              // Should Link Highlight
              let shouldHighlight = window.location.pathname.toLowerCase().indexOf((link.url || "").toLowerCase()) !== -1;
              // If Homepage do special code because all links include "/"
              if(link.url==='/') shouldHighlight = (link.url && link.url.toLowerCase()) === (window.location.pathname && window.location.pathname.toLowerCase()) ? true : false;

              const hightlightStyles = shouldHighlight ? styles.highlight : {};

              return (
                <span 
                  onMouseEnter={() => setHoverId(index)} 
                  onMouseLeave={() => setHoverId(-1)}
                  onClick={() => setHoverId(-1)}
                  key={index}
                >
                  <Link href={link.url} >
                    <Tab style={hightlightStyles}>
                      {link.overide || link.name}
                    </Tab>
                    {shouldHighlight && <div style={{ height: 2, backgroundColor: 'white', }}></div>}
                  </Link>

                  <div style={styles.insideContainer}>
                    {link && link.subMenu && link.subMenu.map((subLink, subIndex) => {
                      
                      // Should Link Highlight
                      let shouldHighlightSub = window.location.pathname.indexOf(subLink.url) !== -1;
                      const hightlightStylesSub = shouldHighlightSub ? styles.highlight : {};
                      const shouldHighlightSubStyles = shouldHighlightSub ? {  display: 'inline-block', borderBottomWidth: 2, borderBottomStyle: 'solid', paddingBottom: 3, borderColor: 'white' } : {};

                      return (
                        <Link key={`s-${subIndex}}`} href={subLink.url} >
                          <div  style={{...styles.insideLink, ...hightlightStylesSub, ...(currentIntervalTick > subIndex - 1 && hoveringId === index) ? { height: 'auto', transform: `translate(-50%, 0px)`, paddingTop: 10, paddingBottom: 10, opacity: 1, zIndex: 1 } : {} }}>
                            <div style={shouldHighlightSubStyles}>
                              {subLink.name}
                            </div>
                          </div>
                        </Link>
                      )
                    }
                  )}
                  </div>
                  
                </span>)
              }
            )}

          </div>}

        </div>
      </div>
    </>
  );
}
