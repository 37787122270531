import React from 'react';
import {Image} from '../components/Image';
import {getSmileServices} from './smileServices';

const getNavItems = () => {
  const smileServices = getSmileServices();

  let formattedSmileServices = [];
  Object.keys(smileServices).forEach((smileServiceIndex) => {
    if(smileServices[smileServiceIndex].showInSmileServiceNav){
      formattedSmileServices.push({ name: smileServices[smileServiceIndex].name, url: `/${smileServices[smileServiceIndex].shortName}` });
    }
  });

  let formattedVeneers = [];
  Object.keys(smileServices).forEach((smileServiceIndex) => {
    if(smileServices[smileServiceIndex].showInVeneersNav){
      formattedVeneers.push({ name: smileServices[smileServiceIndex].name, url: `/${smileServices[smileServiceIndex].shortName}` });
    }
  });

  let formattedGalleries = [];
  Object.keys(smileServices).forEach((smileServiceIndex) => {
    const galleryInfo = smileServices[smileServiceIndex];
    // console.log('galleryInfo',galleryInfo)
    let galleryName = galleryInfo.name && galleryInfo.name.split(' ').join('');
    galleryName = galleryName.replace('®','');
    if(!galleryInfo.dontShowGallery) formattedGalleries.push({ name: `${galleryInfo.name} Gallery`, url: `/gallery/${galleryInfo.galleryId}/${galleryName}` });
  });

  

  return [
    { 
      name: 'Welcome',
      nameMobile: 'Welcome',
      url: '/',
    },
    { 
      name: (<>Porcelain<br/>Veneers</>),
      nameMobile: (<>Porcelain Veneers</>),
      url: '/PorcelainVeneers',
      subMenu: [
        { name: 'Porcelain Veneers', url: '/PorcelainVeneers' },
        { name: 'Porcelain Veneers Before & After Photo Galleries', url: '/PorcelainVeneers?scrollTo=Galleries' },
        ...formattedVeneers,
        // { name: 'Porcelain Veneers FAQs', url: '/PorcelainVeneers/FAQs' },
        { name: 'Porcelain Veneers Testimonials & Reviews', url: '/Testimonials/Veneer' },
      ],
    },
    {
      name: (<>Smile Makeovers & <br/>Full Mouth Treatment</>),
      nameMobile: (<>Smile Makeovers & Full Mouth Treatment</>),
      url: '/SmileMakeovers',
      subMenu: [
        { name: 'Smile Makeovers & Full Mouth Treatment', url: '/SmileMakeovers' },
        { name: 'Smile Makeovers Before & After Photo Gallery', url: '/gallery/51' },
        { name: 'Computer Smile Imaging', url: '/ComputerSmileImaging' },
        { name: 'Steps to a Smile Makeover', url: '/SmileMakeovers/StepsToASmileMakeover' },  
        { name: 'A Smile Makeover Story', url: '/SmileMakeovers/ASmileMakeoverStory' },  
              
      ],
    },
    { 
      name: 'Invisalign',
      nameMobile: 'Invisalign',
      overide: (<Image src={'https://storage.googleapis.com/smiledentistdotcomassets/invislwhite.png'} style={{ width: 100, height: 24 }} />),
      url: '/Invisalign',
      subMenu: [
        { name: 'Invisalign', url: '/Invisalign' },
        { name: 'Invisalign Before & After Photos', url: '/gallery/14' },
        { name: 'Examples of Treatable Cases', url: '/Invisalign/ExamplesOfTreatableCases' },
        { name: 'Invisalign TEEN', url: '/Invisalign/Teen' },
        { name: 'Invisalign Testimonials & Reviews', url: '/Testimonials/Invisalign' },
        { name: 'What is Invisalign?', url: '/Invisalign/WhatIsInvisalign' },
        { name: 'Invisalign FAQs', url: '/Invisalign/FAQs' },
        { name: 'Invisalign Videos', url: '/Invisalign/Videos' },
        { name: '3D iTero Digital Scanner', url: '/iTeroDigitalScanner' },
      ],
    },
    { 
      name: (<>Other Smile<br/>Services</>),
      nameMobile: (<>Other Smile Services</>),
      url: '/SmileServices',
      subMenu: [
        { name: 'Other Smile Services', url: '/SmileServices' },
        ...formattedSmileServices,
      ],
    },
    { 
      name: (<>Cosmetic Concerns<br/>and Solutions</>),
      nameMobile: (<>Cosmetic Concerns and Solutions</>),
      url: '/CosmeticConcernsAndSolutions',
      subMenu: [
        { name: 'Cosmetic Concerns and Solutions', url: '/CosmeticConcernsAndSolutions' },
        { name: 'Crooked & Crowded Teeth', url: '/CosmeticConcernsAndSolutions/CrookedAndCrowdedTeeth' },
        { name: 'Chipped & Cracked Teeth', url: '/CosmeticConcernsAndSolutions/ChippedAndCrackedTeeth' },
        { name: 'Discoloured & Stained Teeth', url: '/CosmeticConcernsAndSolutions/DiscolouredAndStainedTeeth' },
        { name: 'Spaces & Small Gaps', url: '/CosmeticConcernsAndSolutions/SpacesAndSmallGaps' },
        { name: 'Missing Teeth & Large Gaps', url: '/CosmeticConcernsAndSolutions/MissingTeethAndLargeGaps' },
        { name: 'Gummy Smiles & Short Teeth', url: '/CosmeticConcernsAndSolutions/GummySmilesAndShortTeeth' },
        { name: 'Receding & Gumline Issues', url: '/CosmeticConcernsAndSolutions/RecedingAndGumlineIssues' },
        { name: 'Old Silver Fillings', url: '/CosmeticConcernsAndSolutions/OldSilverFillings' },
        { name: 'Old Crowns & Bridges', url: '/CosmeticConcernsAndSolutions/OldCrownsAndBridges' },
        { name: 'Denture Problems', url: '/CosmeticConcernsAndSolutions/DentureProblems' },
      ],
    },
    { 
      name: (<>Before and After<br/> Photo Galleries</>),
      nameMobile: (<>Before and After Photo Galleries</>),
      url: '/BeforeandAfterPhotoGalleries',
      subMenu: [
        { name: 'Before and After Photo Galleries', url: '/BeforeandAfterPhotoGalleries' },
        ...formattedGalleries,
      ]
    },
    { 
      name: (<>Testimonials <br/>& Reviews</>),
      nameMobile: (<>Testimonials & Reviews</>),
      url: '/Testimonials',
    },
    { 
      name: <>Dr. Bruno<br/>Paliani</>,
      nameMobile: <>Dr. Bruno<br/>Paliani</>,
      url: '/DrBrunoPaliani',
    },
    { 
      name: (<>Patient<br/>Information</>),
      nameMobile: (<>Patient Information</>),
      url: '/PatientInformation',
      subMenu: [
        { name: 'Patient Information', url: '/PatientInformation' },
        { name: 'Payment Options', url: '/PatientInformation?scrollTo=PaymentOptions' },
        { name: 'Financing Options (OAC)', url: '/PatientInformation?scrollTo=FinancingOptions' },
        { name: 'Dental Insurance', url: '/PatientInformation?scrollTo=DentalInsurance' },
        { name: 'Our Technology', url: '/PatientInformation?scrollTo=OurTechnology' },
        { name: 'SmileDentist Newsletter Blog', url: '/SmileDentistNewsletterBlog' },
      ],
    },
    { 
      name: 'Contact Us',
      nameMobile: 'Contact Us',
      url: '/ContactUs',
      subMenu: [
        { name: 'Contact Us', url: '/ContactUs' },
        { name: 'Contact Info', url: '/ContactUs?scrollTo=ContactInfo' },
        { name: 'Send us a Messge', url: '/ContactUs?scrollTo=SendUsAMessge' },
        { name: 'Location', url: '/ContactUs?scrollTo=Location' },
        { name: 'Street View', url: '/ContactUs?scrollTo=StreetView' },
      ],
    },
  ];
}
export default getNavItems;
