import {theme} from "../../styles/theme";

export const button = {
  margin: 5,
  textAlign: 'center',
  textShadow: '0px -1px 0px black',
  display: 'inline-block',
  borderRadius: 1,
  fontSize: 14,
  cursor: 'pointer',
  minWidth: 80,
  boxShadow:' 0px 2px 2px rgba(0,0,0,0.2)',
  fontWeight: 'bold',
  padding: '12px 21px',
  backgroundColor: theme.colors.purple,
  color: 'white',
  boxSizing: 'border-box',
}

export const small = {
  fontSize: 12,
  padding: '10px 15px',
  boxShadow:' 0px 1px 2px rgba(0,0,0,0.15)',
}

export const white = {
  backgroundColor: 'white',
  border: `1px solid ${theme.colors.purple}`,
  color: theme.colors.purple,
  textShadow: 'none',
}

export const gradient = {
  backgroundImage: `url(https://storage.googleapis.com/smiledentistdotcomassets/whiteBackground.jpg)`,
  backgroundSize: '100% 100%',
  textShadow: 'none',
  color: theme.colors.purple,
  border: `1px solid #f3f3f3`,
}
