import React from 'react';
import { retry } from '../helpers/webpack';

const NewPatientForms = React.lazy(() =>
  retry(() => import('../pages/Forms/NewPatientForms').then(module => ({ default: module.NewPatientForms })))
);
const PatientInfoForm = React.lazy(() =>
  retry(() => import('../pages/Forms/NewPatientForms/PatientInfo').then(module => ({ default: module.PatientInfoForm })))
);
const MedicalHistoryForm = React.lazy(() =>
  retry(() => import('../pages/Forms/NewPatientForms/MedicalHistory').then(module => ({ default: module.MedicalHistoryForm })))
);
const InsuranceForm = React.lazy(() =>
  retry(() => import('../pages/Forms/NewPatientForms/Insurance').then(module => ({ default: module.InsuranceForm })))
);
const PhotoConsentForm = React.lazy(() =>
  retry(() => import('../pages/Forms/NewPatientForms/PhotoConsent').then(module => ({ default: module.PhotoConsentForm })))
);
const PrivacyConsentForm = React.lazy(() =>
  retry(() => import('../pages/Forms/NewPatientForms/PrivacyConsent').then(module => ({ default: module.PrivacyConsentForm })))
);
const SmileEvaluationForm = React.lazy(() =>
  retry(() => import('../pages/Forms/NewPatientForms/SmileEvaluation').then(module => ({ default: module.SmileEvaluationForm })))
);

const SmileMakeovers = React.lazy(() =>
  retry(() => import('../pages/SmileServices/SmileMakeovers').then(module => ({ default: module.SmileMakeovers })))
);
const LandingPage = React.lazy(() =>
  retry(() => import('../pages/LandingPage').then(module => ({ default: module.LandingPage })))
);
const CosmeticConcernsAndSolutions = React.lazy(() =>
  retry(() => import('../pages/CosmeticConcernsAndSolutions').then(module => ({ default: module.CosmeticConcernsAndSolutions })))
);
const GalleryList = React.lazy(() =>
  retry(() => import('../pages/GalleryList').then(module => ({ default: module.GalleryList })))
);
const GalleryPage = React.lazy(() =>
  retry(() => import('../pages/GalleryPage').then(module => ({ default: module.GalleryPage })))
);
const InfoSession = React.lazy(() =>
  retry(() => import('../pages/InfoSession').then(module => ({ default: module.InfoSession })))
);
const ContactPage = React.lazy(() =>
  retry(() => import('../pages/ContactPage').then(module => ({ default: module.ContactPage })))
);
const PaymentPage = React.lazy(() =>
  retry(() => import('../pages/ContactPage/payment').then(module => ({ default: module.PaymentPage })))
);
const PatientInformation = React.lazy(() =>
  retry(() => import('../pages/PatientInformation').then(module => ({ default: module.PatientInformation })))
);
const DrPaliani = React.lazy(() =>
  retry(() => import('../pages/DrPaliani').then(module => ({ default: module.DrPaliani })))
);
const Privacy = React.lazy(() =>
  retry(() => import('../pages/Privacy').then(module => ({ default: module.Privacy })))
);
const Testimonials = React.lazy(() =>
  retry(() => import('../pages/Testimonials').then(module => ({ default: module.Testimonials })))
);
const SmileDentistNewsletterBlog = React.lazy(() =>
  retry(() => import('../pages/SmileDentistNewsletterBlog').then(module => ({ default: module.SmileDentistNewsletterBlog })))
);
const ITeroDigitalScanner = React.lazy(() =>
  retry(() => import('../pages/OurTechnology/ITeroDigitalScanner').then(module => ({ default: module.ITeroDigitalScanner })))
);
const NVLaser = React.lazy(() =>
  retry(() => import('../pages/OurTechnology/NVLaser').then(module => ({ default: module.NVLaser })))
);
const ComputerSmileImaging = React.lazy(() =>
  retry(() => import('../pages/OurTechnology/ComputerSmileImaging').then(module => ({ default: module.ComputerSmileImaging })))
);
const DigitalXrays = React.lazy(() =>
  retry(() => import('../pages/OurTechnology/DigitalXrays').then(module => ({ default: module.DigitalXrays })))
);
const FreeExamAppointment = React.lazy(() =>
  retry(() => import('../pages/OurTechnology/FreeExamAppointment').then(module => ({ default: module.FreeExamAppointment })))
);
const OralVideoCamera = React.lazy(() =>
  retry(() => import('../pages/OurTechnology/OralVideoCamera').then(module => ({ default: module.OralVideoCamera })))
);
const DigitalCameras = React.lazy(() =>
  retry(() => import('../pages/OurTechnology/DigitalCameras').then(module => ({ default: module.DigitalCameras })))
);
const AirAbrasion = React.lazy(() =>
  retry(() => import('../pages/OurTechnology/AirAbrasion').then(module => ({ default: module.AirAbrasion })))
);
const MagnifyingTelescopes = React.lazy(() =>
  retry(() => import('../pages/OurTechnology/MagnifyingTelescopes').then(module => ({ default: module.MagnifyingTelescopes })))
);
const DentalwareImagewareChartware = React.lazy(() =>
  retry(() => import('../pages/OurTechnology/DentalwareImagewareChartware').then(module => ({ default: module.DentalwareImagewareChartware })))
);
const Penguin = React.lazy(() =>
  retry(() => import('../pages/OurTechnology/Penguin').then(module => ({ default: module.Penguin })))
);
const SmileServicesListPage = React.lazy(() =>
  retry(() => import('../pages/SmileServices/ListPage').then(module => ({ default: module.SmileServicesListPage })))
);
const UltraThinVeneers = React.lazy(() =>
  retry(() => import('../pages/SmileServices/UltraThinVeneers').then(module => ({ default: module.UltraThinVeneers })))
);
const UltraThinVeneersLandingPage = React.lazy(() =>
  retry(() => import('../pages/SmileServices/UltraThinVeneers/LandingPage').then(module => ({ default: module.UltraThinVeneersLandingPage })))
);
const UTVgalp = React.lazy(() =>
  retry(() => import('../pages/SmileServices/UltraThinVeneers/galp').then(module => ({ default: module.UTVgalp })))
);
const InlaysAndOnlays = React.lazy(() =>
  retry(() => import('../pages/SmileServices/InlaysAndOnlays').then(module => ({ default: module.InlaysAndOnlays })))
);
const OneAppointmentBonding = React.lazy(() =>
  retry(() => import('../pages/SmileServices/OneAppointmentBonding').then(module => ({ default: module.OneAppointmentBonding })))
);
const TeethWhitening = React.lazy(() =>
  retry(() => import('../pages/SmileServices/TeethWhitening').then(module => ({ default: module.TeethWhitening })))
);
const EstheticDentures = React.lazy(() =>
  retry(() => import('../pages/SmileServices/EstheticDentures').then(module => ({ default: module.EstheticDentures })))
);
const EstheticImplants = React.lazy(() =>
  retry(() => import('../pages/SmileServices/EstheticImplants').then(module => ({ default: module.EstheticImplants })))
);
const EstheticBridges = React.lazy(() =>
  retry(() => import('../pages/SmileServices/EstheticBridges').then(module => ({ default: module.EstheticBridges })))
);
const ToothColouredFillings = React.lazy(() =>
  retry(() => import('../pages/SmileServices/ToothColouredFillings').then(module => ({ default: module.ToothColouredFillings })))
);
const SnapOnSmile = React.lazy(() =>
  retry(() => import('../pages/SmileServices/SnapOnSmile').then(module => ({ default: module.SnapOnSmile })))
);
const ModifiedPinholeGumGrafting = React.lazy(() =>
  retry(() => import('../pages/SmileServices/ChaoPinholeGumGrafting').then(module => ({ default: module.ModifiedPinholeGumGrafting })))
);
const PorcelainVeneers = React.lazy(() =>
  retry(() => import('../pages/SmileServices/PorcelainVeneers/newIndex').then(module => ({ default: module.PorcelainVeneers })))
);
const PorcelainVeneersLandingPage = React.lazy(() =>
  retry(() => import('../pages/SmileServices/PorcelainVeneers/LandingPage').then(module => ({ default: module.PorcelainVeneersLandingPage })))
);
const Lumineers = React.lazy(() =>
  retry(() => import('../pages/SmileServices/Lumineers').then(module => ({ default: module.Lumineers })))
);
const PorcelainVeneersUpper8to10Teeth = React.lazy(() =>
  retry(() => import('../pages/SmileServices/PorcelainVeneers/Upper8to10Teeth').then(module => ({ default: module.PorcelainVeneersUpper8to10Teeth })))
);
const PorcelainVeneersUpper6Teeth = React.lazy(() =>
  retry(() => import('../pages/SmileServices/PorcelainVeneers/Upper6Teeth').then(module => ({ default: module.PorcelainVeneersUpper6Teeth })))
);
const PorcelainVeneersUpper4Teeth = React.lazy(() =>
  retry(() => import('../pages/SmileServices/PorcelainVeneers/Upper4Teeth').then(module => ({ default: module.PorcelainVeneersUpper4Teeth })))
);
const PorcelainVeneersFAQs = React.lazy(() =>
  retry(() => import('../pages/SmileServices/PorcelainVeneers/FAQs').then(module => ({ default: module.PorcelainVeneersFAQs })))
);
const StartingInvisalignTreatment = React.lazy(() =>
  retry(() => import('../pages/StartingInvisalignTreatment').then(module => ({ default: module.StartingInvisalignTreatment })))
);
const Invisalign = React.lazy(() =>
  retry(() => import('../pages/SmileServices/Invisalign/Main').then(module => ({ default: module.Invisalign })))
);
const InvisalignTeen = React.lazy(() =>
  retry(() => import('../pages/SmileServices/Invisalign/Teen').then(module => ({ default: module.InvisalignTeen })))
);
const ExamplesOfTreatableCases = React.lazy(() =>
  retry(() => import('../pages/SmileServices/Invisalign/ExamplesOfTreatableCases').then(module => ({ default: module.ExamplesOfTreatableCases })))
);
const FAQs = React.lazy(() =>
  retry(() => import('../pages/SmileServices/Invisalign/FAQs').then(module => ({ default: module.FAQs })))
);
const Videos = React.lazy(() =>
  retry(() => import('../pages/SmileServices/Invisalign/Videos').then(module => ({ default: module.Videos })))
);
const WhatIsInvisalign = React.lazy(() =>
  retry(() => import('../pages/SmileServices/Invisalign/WhatIsInvisalign').then(module => ({ default: module.WhatIsInvisalign })))
);
const ImplantsOffer = React.lazy(() =>
  retry(() => import('../pages/Offers/ImplantsOffer').then(module => ({ default: module.ImplantsOffer })))
);
const InvisalignOffer = React.lazy(() =>
  retry(() => import('../pages/Offers/InvisalignOffer').then(module => ({ default: module.InvisalignOffer })))
);
const CosmeticOffer = React.lazy(() =>
  retry(() => import('../pages/Offers/CosmeticOffer').then(module => ({ default: module.CosmeticOffer })))
);
const SnapOnSmileOffer = React.lazy(() =>
  retry(() => import('../pages/Offers/SnapOnSmileOffer').then(module => ({ default: module.SnapOnSmileOffer })))
);
const SmileMakeoversOffer = React.lazy(() =>
  retry(() => import('../pages/Offers/SmileMakeoversOffer').then(module => ({ default: module.SmileMakeoversOffer })))
);
const ChaoPinholeGumGraftingOffer = React.lazy(() =>
  retry(() => import('../pages/Offers/ChaoPinholeGumGraftingOffer').then(module => ({ default: module.ChaoPinholeGumGraftingOffer })))
);
const ThankYou = React.lazy(() =>
  retry(() => import('../pages/ThankYou/old').then(module => ({ default: module.ThankYou })))
);
const ThankYouForm = React.lazy(() =>
  retry(() => import('../pages/ThankYou/form').then(module => ({ default: module.ThankYouForm })))
);
const InvisalignGalp = React.lazy(() =>
  retry(() => import('../pages/SmileServices/Invisalign/Main/galp').then(module => ({ default: module.InvisalignGalp })))
);
const IGlinks = React.lazy(() =>
  retry(() => import('../pages/IGlinks').then(module => ({ default: module.IGlinks })))
);

/**
 * IMPORTANT
 */

// If you change this make sure the buildSiteMap script doesnt break as it parse this file
// MUST HAVE TRAILING COMMAS INSIDE THE OBJECTS!!!!!!!! or it will break the build!!!!
export default [

  { exact: true, route: "/ThankYou/:currentPage", component: ThankYouForm, hideFromSearchEngine: true, },
  { exact: true, route: "/ThankYou", component: ThankYou, hideFromSearchEngine: true, },

  { exact: true, route: "/offer/Invisalign", component: InvisalignOffer, hideFromSearchEngine: true, },
  { exact: true, route: "/offer/Cosmetic", component: CosmeticOffer, hideFromSearchEngine: true, },
  { exact: true, route: "/offer/SmileMakeovers", component: SmileMakeoversOffer, hideFromSearchEngine: true, },
  { exact: true, route: "/offer/Implants", component: ImplantsOffer, hideFromSearchEngine: true, },
  { exact: true, route: "/offer/ChaoPinholeGumGrafting", component: ChaoPinholeGumGraftingOffer, hideFromSearchEngine: true, }, 
  { exact: true, route: "/offer/SnapOnSmile", component: SnapOnSmileOffer, hideFromSearchEngine: true, },

  { exact: true, route: "/BeforeandAfterPhotoGalleries", component: GalleryList, },
  { exact: true, route: "/gallery/:id/*", component: GalleryPage, },
  { exact: true, route: "/gallery/:id", component: GalleryPage, },
  { exact: false, route: "/CosmeticConcernsAndSolutions/:scrollTo", component: CosmeticConcernsAndSolutions, },            
  { exact: true, route: "/CosmeticConcernsAndSolutions", component: CosmeticConcernsAndSolutions, },  
  { exact: true, route: "/SmileDentistNewsletterBlog", component: SmileDentistNewsletterBlog, },     
      
  { exact: true, route: "/Technology", redirect: '/PatientInformation/OurTechnology', }, 
  { exact: true, route: "/PatientInformation/:scrollTo", component: PatientInformation, },
  { exact: true, route: "/PatientInformation", component: PatientInformation, },
  { exact: false, route: "/ContactUs/:scrollTo", component: ContactPage, },
  { exact: false, route: "/ContactUs", component: ContactPage, },
  { exact: false, route: "/Payment", component: PaymentPage, },
  { exact: false, route: "/Payments", component: PaymentPage, },
  { exact: false, route: "/payments", component: PaymentPage, },
  { exact: true, route: "/DrPaliani", redirect: '/DrBrunoPaliani' },
  { exact: true, route: "/DrBrunoPaliani", component: DrPaliani, },
  { exact: true, route: "/Privacy", component: Privacy, },
  { exact: true, route: "/Testimonials", component: Testimonials, },
  { exact: true, route: "/Testimonials/:searchTerm", component: Testimonials, },
  { exact: true, route: "/InfoSession", component: InfoSession, },

  { exact: true, route: "/SmileServices", component: SmileServicesListPage, },
  { exact: true, route: "/SmileMakeovers/ThankYou", component: SmileMakeovers, },
  { exact: true, route: "/SmileMakeovers/:scrollTo", component: SmileMakeovers, },
  { exact: true, route: "/SmileMakeovers", component: SmileMakeovers, },
  { exact: true, route: "/UltraThinPorcelainVeneers/ThankYou", component: UltraThinVeneers, },

  { exact: true, route: "/PorcelainVeneers/ThankYou", component: PorcelainVeneers, },
  
  { exact: true, route: "/UltraThinVeneers", redirect: "/UltraThinPorcelainVeneers", },
  { exact: true, route: "/UltraThinVeneers/ThankYou", redirect: "/UltraThinPorcelainVeneers/ThankYou", },
  { exact: true, route: "/UltraThinPorcelainVeneers/IGFBLP", component: UltraThinVeneersLandingPage, },
  { exact: true, route: "/UltraThinPorcelainVeneers/GALP", component: UTVgalp, },
  { exact: true, route: "/UltraThinPorcelainVeneers", component: UltraThinVeneers, },
  { exact: true, route: "/PorcelainVeneersAndCrowns", redirect: "/PorcelainVeneers", },

  { exact: true, route: "/InlaysAndOnlays/ThankYou", component: InlaysAndOnlays, },
  { exact: true, route: "/InlaysAndOnlays", component: InlaysAndOnlays, },
  { exact: false, route: "/OneAppointmentBonding/:scrollTo", component: OneAppointmentBonding, },
  { exact: true, route: "/OneAppointmentBonding/ThankYou", component: OneAppointmentBonding, },
  { exact: true, route: "/OneAppointmentBonding", component: OneAppointmentBonding, },
  { exact: true, route: "/TeethWhitening/ThankYou", component: TeethWhitening, },
  { exact: true, route: "/TeethWhitening", component: TeethWhitening, },
  { exact: true, route: "/EstheticDentures/ThankYou", component: EstheticDentures, },
  { exact: true, route: "/EstheticDentures", component: EstheticDentures, },
  { exact: true, route: "/EstheticImplants/ThankYou", redirect: '/EstheticDentalImplants/ThankYou', },
  { exact: true, route: "/EstheticImplants", redirect: '/EstheticDentalImplants', },
  { exact: true, route: "/EstheticDentalImplants/ThankYou", component: EstheticImplants, },
  { exact: true, route: "/EstheticDentalImplants", component: EstheticImplants, },
  { exact: true, route: "/EstheticDentalBridges/ThankYou", component: EstheticBridges, },
  { exact: true, route: "/EstheticDentalBridges", component: EstheticBridges, },
  { exact: true, route: "/EstheticBridges/ThankYou", redirect: '/EstheticDentalBridges/ThankYou', },
  { exact: true, route: "/EstheticBridges", redirect: '/EstheticDentalBridges', },
  { exact: true, route: "/ToothColouredFillings/ThankYou", component: ToothColouredFillings, },
  { exact: true, route: "/ToothColouredFillings", component: ToothColouredFillings, },
  { exact: true, route: "/SnapOnSmile/ThankYou", component: SnapOnSmile, },
  { exact: true, route: "/SnapOnSmile", component: SnapOnSmile, },
  { exact: true, route: "/ChaoPinholeGumGrafting/ThankYou", component: ModifiedPinholeGumGrafting, },
  { exact: true, route: "/ChaoPinholeGumGrafting", component: ModifiedPinholeGumGrafting, },
  { exact: true, route: "/ModifiedPinholeGumGrafting", redirect: '/ChaoPinholeGumGrafting', },
  { exact: true, route: "/PorcelainVeneers", component: PorcelainVeneers, },
  { exact: true, route: "/PorcelainVeneers/GALP", component: PorcelainVeneersLandingPage, },
  { exact: true, route: "/Lumineers", component: Lumineers, },

  { exact: true, route: "/PorcelainVeneers-Upper8to10Teeth/ThankYou", component: PorcelainVeneersUpper8to10Teeth, },
  { exact: true, route: "/PorcelainVeneers-Upper6Teeth/ThankYou", component: PorcelainVeneersUpper6Teeth, },
  { exact: true, route: "/PorcelainVeneers-Upper4Teeth/ThankYou", component: PorcelainVeneersUpper4Teeth, },

  
  { exact: true, route: "/PorcelainVeneers-Upper8to10Teeth", redirect: '/PorcelainVeneers', }, // component: PorcelainVeneersUpper8to10Teeth,
  { exact: true, route: "/PorcelainVeneers-Upper6Teeth", redirect: '/PorcelainVeneers',  }, // component: PorcelainVeneersUpper6Teeth,
  { exact: true, route: "/PorcelainVeneers-Upper4Teeth", redirect: '/PorcelainVeneers', }, //component: PorcelainVeneersUpper4Teeth


  { exact: true, route: "/PorcelainVeneers/FAQs", component: PorcelainVeneersFAQs, },
  { exact: true, route: "/PorcelainVeneers/FeesAndPaymentOptions", redirect: "/PorcelainVeneers/ThankYou", },

  { exact: true, route: "/Invisalign/ExamplesOfTreatableCases", component: ExamplesOfTreatableCases, },
  { exact: true, route: "/Invisalign/Teen", component: InvisalignTeen, },
  { exact: true, route: "/Invisalign/WhatIsInvisalign", component: WhatIsInvisalign, },
  { exact: true, route: "/Invisalign/FAQs", component: FAQs, },
  { exact: true, route: "/Invisalign/Videos", component: Videos, },
  { exact: true, route: "/Invisalign/ThankYou", component: Invisalign, },
  { exact: true, route: "/Invisalign", component: Invisalign, },
  { exact: true, route: "/Invisalign/galp", component: InvisalignGalp, },
  { exact: true, route: "/Invisalign/mlp", component: InvisalignGalp, },

  { exact: true, route: "/iTeroDigitalScanner", component: ITeroDigitalScanner, },
  { exact: true, route: "/NVLaser", component: NVLaser, },
  { exact: true, route: "/ComputerSmileImaging", component: ComputerSmileImaging, },
  { exact: true, route: "/DigitalXrays", component: DigitalXrays, },
  { exact: true, route: "/FreeExamAppointment", component: FreeExamAppointment, },
  { exact: true, route: "/OralVideoCamera", component: OralVideoCamera, },
  { exact: true, route: "/DigitalCameras", component: DigitalCameras, },
  { exact: true, route: "/AirAbrasion", component: AirAbrasion, },
  { exact: true, route: "/MagnifyingTelescopes", component: MagnifyingTelescopes, },
  { exact: true, route: "/DentalwareImagewareChartware", component: DentalwareImagewareChartware, },
  { exact: true, route: "/Penguin", component: Penguin, },

  { exact: true, route: "/IGlinks", component: IGlinks, },

  { exact: true, route: "/Forms/NewPatientForm/:formUrlId/patient-info", component: PatientInfoForm, },
  { exact: true, route: "/Forms/NewPatientForm/:formUrlId/medical-history", component: MedicalHistoryForm, },
  { exact: true, route: "/Forms/NewPatientForm/:formUrlId/insurance", component: InsuranceForm, },
  { exact: true, route: "/Forms/NewPatientForm/:formUrlId/photo-consent", component: PhotoConsentForm, },
  { exact: true, route: "/Forms/NewPatientForm/:formUrlId/privacy-consent", component: PrivacyConsentForm, },
  { exact: true, route: "/Forms/NewPatientForm/:formUrlId/smile-evaluation", component: SmileEvaluationForm, },
  { exact: true, route: "/Forms/NewPatientForm/:formUrlId", component: NewPatientForms, },

  { exact: true, route: "/starting-invisalign-treatment", component: StartingInvisalignTreatment, },
  
  // This must be last
  { exact: true, route: "/", component: LandingPage, },
  { exact: false, route: "/", component: LandingPage, },

];
