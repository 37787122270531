import React, { Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Route, Navigate, Routes } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import TagManager from 'react-gtm-module';
import ReactGA from 'react-ga4';

import { ScrollToTop } from './components/ScrollToTop';
import { ScrollToElement } from './components/ScrollToElement';
import './styles/globalStyles.css';
import routes from '/thisIsMappedToFolderInWebpack'; // Ensure this alias is correctly mapped
import WrapperComponent from '/thisIsMappedToWrapperInWebpack';

const queryClient = new QueryClient();

const App = () => {

  useEffect(() => {
    setTimeout(() => {
      ReactGA.initialize('G-JYEZ915V6C'); // Initialize Google Analytics
      ReactGA.send("pageview");

      TagManager.initialize({ gtmId: 'GTM-M3QJJLK' });
    }, 5000);
  }, []);

  const loadingContent = (<div style={{ width: "100%", height: "100vh" }}></div>);

  return (
    <Router>
      <ScrollToTop />
      <ScrollToElement />
      {/* <Suspense fallback={loadingContent}> */}
        <QueryClientProvider client={queryClient}>
          <WrapperComponent>
            <Routes>
              {routes.map((route, idx) => {
                if (route.redirect) {
                  return (
                    <Route
                      key={idx}
                      path={route.route}
                      element={<Navigate to={route.redirect} replace />}
                    />
                  );
                }
                return (
                  <Route
                    key={idx}
                    path={route.route}
                    element={<Suspense fallback={loadingContent}><route.component /></Suspense>}
                  />
                );
              })}
            </Routes>
          </WrapperComponent>
        </QueryClientProvider>
      {/* </Suspense> */}
    </Router>
  );
};

export default App;