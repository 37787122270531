import React from 'react';
import {Footer} from '../../../components/Footer';
import {Nav as NavComponent} from '../../../components/Nav';
import {SubNav} from '../../../components/SubNav';
import { useLocation } from 'react-router';
import { LazyLoad } from '../../LazyLoad';

export default (props) => {
  const location = useLocation();

  const pathName = location.pathname.toLowerCase()

  const hideFooterAndNav = pathName.includes('/galp') || pathName.includes('/mlp') || pathName.includes('/iglinks');

  return(
    <React.Fragment>

      {!hideFooterAndNav && <NavComponent />}
      {!hideFooterAndNav && <SubNav />}

      {props.children}

      {!hideFooterAndNav && <LazyLoad><Footer /></LazyLoad>}

    </React.Fragment>
  );
}

