import {theme} from '../../styles/theme';

export const logo = {
  height: 70,
}

export const footerContainer = {
  backgroundColor: "#F6F6F6",
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  padding: 20,
  flexDirection: 'column',
  justifyContent: 'center',
  boxSizing: 'border-box',
  textAlign: 'center',
}

export const footerAddress = {
  fontSize: 20,
  color: theme.colors.lightGrey,
}

export const footerLink = {
  fontSize: 10,
  color: theme.colors.gray,
  display: 'inline-block',
  textAlign: 'center',
  paddingLeft: 10,
  paddingRight: 10,
  verticalAlign: 'center',
}

export const footerLinkHolder = {
  display: 'flex', 
  alignItems: 'center',
  flexDirection: 'row',
  justifyContent: 'center',
}

export const copyright = {
  color: theme.colors.gray,
  fontSize: 10,
}

export const verticalLine = {
  backgroundColor: theme.colors.lightGrey,
  opacity: 0.3,
  display: 'inline-block',
  width: 1,
  height: 25,
  verticalAlign: 'top',
  marginLeft: 5,
}