import CMSdoc from './CMS/smileServices';

export const getSmileServices = () => {

  const CMS = CMSdoc[0];

  return {    
    PorcelainRestorations4UpperTeeth: {
      name: CMS.PorcelainRestorations4UpperTeeth || "Porcelain Veneers- 4 Upper Teeth",
      shortName: 'PorcelainVeneers-Upper4Teeth',
      thankYouPageLinkOveride: 'PorcelainVeneers/ThankYou',
      galleryId: 111,
      showOnThankYouPage: false,
      showInVeneersNav: false,
      showGalleryOnVeneersPage: true,
    },
    PorcelainRestorations6UpperTeeth: {
      name: CMS.PorcelainRestorations6UpperTeeth || "Porcelain Veneers- 6 Upper Teeth",
      shortName: 'PorcelainVeneers-Upper6Teeth',
      thankYouPageLinkOveride: 'PorcelainVeneers/ThankYou',
      galleryId: 112,
      showOnThankYouPage: false,
      showInVeneersNav: false,
      showGalleryOnVeneersPage: true,
    },
    PorcelainRestorations8to10UpperTeeth: {
      name: CMS.PorcelainRestorations8to10UpperTeeth || "Porcelain Veneers- 8 - 10 Upper Teeth",
      shortName: 'PorcelainVeneers-Upper8to10Teeth',
      thankYouPageLinkOveride: 'PorcelainVeneers/ThankYou',
      galleryId: 113,
      showOnThankYouPage: false,
      showInVeneersNav: false,
      showGalleryOnVeneersPage: true,
    },
    UltraThinVeneers: {
      name: CMS.UltraThinVeneers || 'Ultra Thin Porcelain Veneers',
      shortName: 'UltraThinPorcelainVeneers',
      galleryId: 50,
      showOnThankYouPage: true,
      showInVeneersNav: true,
      showGalleryOnVeneersPage: true,
    },
    SmileMakeovers: {
      name: CMS.SmileMakeovers || 'Smile Makeovers & Full Mouth Treatment',
      shortName: 'SmileMakeovers',
      galleryId: 51,
      showOnThankYouPage: true,
      showInVeneersNav: true,
      showGalleryOnVeneersPage: true,
    },
    Lumineers: {
      name: CMS.Lumineers || 'Lumineers',
      shortName: 'Lumineers',
      galleryId: 0,
      dontShowGallery: true,
      showInVeneersNav: true,
      showGalleryOnVeneersPage: true,
    },
    // PorcelainVeneersAndCrowns: {
    //   name: CMS.PorcelainVeneersAndCrowns || 'Porcelain Veneers - All Types',
    //   shortName: 'PorcelainVeneersAndCrowns',
    //   galleryId: 11,
    //   showOnThankYouPage: true,
    // },
    Invisalign: {
      name: CMS.Invisalign || 'Invisalign®',
      shortName: 'Invisalign',
      galleryId: 14,
      showOnThankYouPage: true,
    },
    InlaysAndOnlays: {
      name: CMS.InlaysAndOnlays || 'Inlays & Onlays',
      shortName: 'InlaysAndOnlays',
      galleryId: 15,
      showOnThankYouPage: true,
      showInSmileServiceNav: true,
    },
    OneAppointmentBonding: {
      name: CMS.OneAppointmentBonding || 'Cosmetic Dental Bonding',
      shortName: 'OneAppointmentBonding',
      galleryId: 17,
      showOnThankYouPage: true,
      showInSmileServiceNav: true,
    },
    TeethWhitening: {
      name: CMS.TeethWhitening || 'Teeth Whitening',
      shortName: 'TeethWhitening',
      galleryId: 19,
      showOnThankYouPage: true,
      showInSmileServiceNav: true,
    },
    EstheticDentures: {
      name: CMS.EstheticDentures || 'Esthetic Dentures',
      shortName: 'EstheticDentures',
      galleryId: 21,
      showOnThankYouPage: true,
      showInSmileServiceNav: true,
    },
    EstheticImplants: {
      name: CMS.EstheticImplants || 'Esthetic Implants',
      shortName: 'EstheticImplants',
      galleryId: 23,
      showOnThankYouPage: true,
      showInSmileServiceNav: true,
    },
    EstheticBridges: {
      name: CMS.EstheticBridges || 'Esthetic Dental Bridges',
      shortName: 'EstheticDentalBridges',
      galleryId: 25,
      showOnThankYouPage: true,
      showInSmileServiceNav: true,
    },
    ToothColouredFillings: {
      name: CMS.ToothColouredFillings || 'Tooth-Coloured Fillings',
      shortName: 'ToothColouredFillings',
      galleryId: 27,
      showInSmileServiceNav: true,
      showOnThankYouPage: true,
    }, 
    ModifiedPinholeGumGrafting: {
      name: CMS.ModifiedPinholeGumGrafting || 'Chao Pinhole® Gum​ Grafting',
      shortName: 'ChaoPinholeGumGrafting',
      galleryId: 57,
      // dontShowGallery: true,
      showInSmileServiceNav: true,
      showOnThankYouPage: true,
    },
    SnapOnSmile: {
      name: CMS.SnapOnSmile || 'Snap-On Smile',
      shortName: 'SnapOnSmile',
      galleryId: 108,
      // dontShowGallery: true,
      showInSmileServiceNav: true,
      showOnThankYouPage: true,
    },
    
  }
}
