import { deviceSize } from '../../helpers/device';
import {theme} from "../../styles/theme";

export default () => {
  const device = deviceSize();

  return {
    navHeight: {
      height: 50,
    },
    phone: {
      fontSize: 25,
      marginLeft: 20,
    },
    bars: {
      fontSize: 25,
      marginLeft: 20,
    },
    navBarContainer: {
      backgroundColor: 'black', //theme.colors.darkPurple,
      position: 'fixed',
      top: 70,
      left: 0,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      zIndex: "99999",
    },
    linkHolder: {
      height: '100%',
      color: 'white',
      width: 'calc(100% - 260px)',
      position: 'absolute',
      top: 0,
      right: 50,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    link: {
      display: 'inline-block',
      fontSize: 12,
      cursor: 'pointer',
      padding: 5,
      textAlign: 'center',
      color: 'white',
    },
    logo: {
      width: 140,
      marginLeft: device === 'desktop' ? 30 : 0,
    },
    insideContainer: {
      display: 'block',
      height: 0,
      width: 0,
      top: '0px',
      position: 'relative',
      textAlign: 'center',
      alignSelf: 'center',
      left: '50%',
    },
    insideLink: {
      backgroundColor: '#3F2C5D',
      color: 'white',
      paddingTop: 0,
      paddingBottom: 0,
      fontSize: 14,
      height: 0,
      overflow: 'hidden',
      transition: 'all 0.2s',
      boxSizing: 'border-box',
      opacity: 0,
      width: 200,
      transform: `translate(-50%, -50px)`,
    },
    highlight: {
      fontWeight: 'bold',
    },
    subTxt:{
      color: 'white',
      fontSize: 18,
      paddingLeft: 20,
    },
    mobileLinkHolder: {
      position: 'absolute',
      top: 60,
      left: 25,
      right: 25,
      textAlign: 'center',
      fontSize: 14,
      borderRadius: 1,
      overflow: 'hidden',
      zIndex: 999,
    },
    mobileLink: {
      backgroundColor: theme.colors.purple,
      color: 'white',
      transition: 'all 0.2s',
      transform: `translate(0px, -50px)`,
      height: 0,
      paddingTop: 0,
      paddingBottom: 0,
      opacity: 0,
      boxSizing: 'border-box',
    },
  }
}