import React from 'react';
import * as styles from './styles';
import {Image} from '../Image';
import {Spacer} from '../Spacer';
import {Button} from '../Button';
import {SocialSideBar} from '../SocialSideBar';
import getNavItems from '../../data/navItems';
import getGeneralData from '../../data/general';

import {Link} from '../Link';
import { deviceSize } from '../../helpers/device';

export const Footer = (props) => {
  const generalData = getGeneralData();
  const navItems = getNavItems();
  const device = deviceSize();
  const currentYear = new Date().getFullYear();

  return (
    <div style={styles.footerContainer}>
      <Image src={'https://storage.googleapis.com/smiledentistdotcomassets/footerlogo.webp'} style={styles.logo} />

      <Spacer top={20} style={props.footerAddress}>{generalData.address.road}, {generalData.address.city}, {generalData.address.province}, {generalData.address.country} {generalData.address.postalCode}</Spacer>

      <Spacer top={20}>
        <Button text={`Call: ${generalData.contact.phone}`} link={`tel:${generalData.contact.phone}`} external small buttonStyle='gradient' />
        <Button data-calltrk-noswap text={<Image src={'https://storage.googleapis.com/smiledentistdotcomassets/textWording.png'} style={{ width: 120, height: 10, verticalAlign: "-2px" }} />} link={`sms:+:${generalData.contact.phone}`} external small buttonStyle='gradient' />
      </Spacer>

      {device === 'desktop' && 
        <Spacer top={20} style={styles.footerLinkHolder}>
          {navItems.map((link, index) => (
          <a key={index} href={link.url}>
            <div style={styles.footerLink}>{link.name}</div>
            <div style={styles.verticalLine}></div>
          </a>))}
        </Spacer>
      }

      <Spacer top={20}>
        <SocialSideBar />
      </Spacer>

      <Spacer top={20} style={styles.copyright}>
        © Copyright {currentYear} {generalData.contact.companyName} V1.0 - <Link href={"/Privacy"}>Privacy Policy</Link>
      </Spacer>

    </div>
  );
}
