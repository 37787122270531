import React from 'react';
import styled from 'styled-components';
import {Image} from './Image';
import {Link} from './Link';

export const Icon = styled.div`
  width: 40px;
  height: 40px;
  cursor: pointer;
  -webkit-transition: all 0.2s;
  box-shadow: 0px 2px 5px rgba(0,0,0,0.2);
  textAlign: center;
  position: relative;
  display: flex;
  background-color: ${props => props.backgroundColor};
  &:hover {
    padding-left: 20px;
  }
`;

export const ImageComponent = styled.img`
  outline: none;
  border: none;
  boxShadow: 0px 0px 0px black;
  marginTop: 0px;
  height: 100%;
  width: 100%;
  flex-shrink: 0;
  position: abosolute;
  top: 0px;
  right: 0px;
`;

export const SocialSideBar = (props) => { 
  return (
    <div style={{
      zIndex: 999999999999,
      display: 'flex',
      flexDirection: 'row',
    }}>
      
      <Link external newTab href={"https://www.instagram.com/smiledentist/"}>
        <Icon backgroundColor={"#984891"} >
          <ImageComponent src={'https://storage.googleapis.com/smiledentistdotcomassets/smi.png'} />
        </Icon>
      </Link>

      <Link external newTab href={"https://www.facebook.com/TheSmileDentist"}>
        <Icon backgroundColor={"#3A588A"}>
          <ImageComponent src={'https://storage.googleapis.com/smiledentistdotcomassets/smfb.png'} />
        </Icon>
      </Link>

      <Link external newTab href={"https://twitter.com/SmileDDSLondon"}>
        <Icon backgroundColor={"#000000"}>
          <ImageComponent src={'https://storage.googleapis.com/smiledentistdotcomassets/smt.png'} />
        </Icon>
      </Link>

      <Link external newTab href={"https://ca.linkedin.com/pub/dr-bruno-paliani/42/9a7/b47"}>
        <Icon backgroundColor={"#387CAF"}>
          <ImageComponent src={'https://storage.googleapis.com/smiledentistdotcomassets/smli.png'} />
        </Icon>
      </Link>

      <Link external newTab href={"https://www.youtube.com/user/SmileDentistLondonON"}>
        <Icon backgroundColor={"#BA312F"} >
          <ImageComponent src={'https://storage.googleapis.com/smiledentistdotcomassets/smyt.png'} />
        </Icon>
      </Link>

    </div>
  );
}