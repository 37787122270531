import React from 'react';
import * as styles from './styles';

import {Link} from '../Link';
import {theme} from '../../styles/theme';

export const Button = (props) => {

    let buttonStyles = styles.button;

    // predefined styles
    // options: white gradient
    if(props.buttonStyle) buttonStyles = { ...buttonStyles, ...styles[props.buttonStyle] }

    // Less padding / text size
    if(props.small) buttonStyles = { ...buttonStyles, ...styles.small}

    // Full width
    if(props.fullWidth) buttonStyles = { ...buttonStyles, width: '100%', margin: 0 }

    buttonStyles = { ...buttonStyles, ...buttonStyles }

    if(props.themeBackgroundColor){
      buttonStyles = { ...buttonStyles, backgroundColor: theme.colors[props.themeBackgroundColor] }
    }

    let button = (<div data-calltrk-noswap={props['data-calltrk-noswap']} onClick={props.onClick} style={buttonStyles}>{props.text}</div>)

    // Wrap In Link if Link Exists
    if(props.link) button = (<Link data-calltrk-noswap={props['data-calltrk-noswap']} newTab={props.newTab} href={props.link} external={props.external} >{button}</Link>);

    return (button);
}