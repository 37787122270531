import React from 'react';


export const Spacer = (props) => {
  // Check if Center

  // const justifyStyle = (!center && !alignRight && !alignLeft) ? { textAlign: 'justify' } : {};

  const centerStyle = props.center ? { textAlign: 'center' } : {};
  const rightStyle = props.alignRight ? { textAlign: 'right' } : {};
  const leftStyle = props.alignLeft ? { textAlign: 'left' } : {};
  const inlineBlockStyle = props.inlineBlock ? { display: 'inline-block' } : {};

  return (
    <div onClick={props.onClick} style={{ ...inlineBlockStyle, paddingTop: props.top, paddingBottom: props.bottom, paddingLeft: props.left, paddingRight: props.right,  ...centerStyle, ...leftStyle, ...rightStyle, ...props.style }}>{props.children}</div>
  );
}
