import React from 'react';
import ReactLazyLoad from 'react-lazy-load';

interface Props {
  offset?: number
  children: React.ReactElement
}

export const LazyLoad = (props: Props) => {
  return (
    <ReactLazyLoad 
      offsetVertical={props.offset || 1000}
      throttle={0}
      once
    >
      {props.children}
    </ReactLazyLoad>
  );
}
