import React, { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { scrollTo } from '../helpers/device';

export const ScrollToElement = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const scrollToElement = searchParams.get('scrollTo');
  const location = useLocation()

  useEffect(() => {

    if (!scrollToElement) {
      return
    }

    scrollTo(scrollToElement);
    
    searchParams.delete("scrollTo");
    setSearchParams(searchParams)

  }, [scrollToElement])

  return <></>
}
