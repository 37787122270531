import CMSdoc from './CMS/general';

export default () => {
  const CMS = CMSdoc[0];

  const data = { 
    contact: {
      companyName: CMS.companyName,
      corporationName: CMS.corporationName,
      name: CMS.name,
      phone: CMS.phone,
      tollFree: CMS.tollFree,
      fax: CMS.fax,
      email: CMS.email,
    },
    address: {
      road: '251 Fanshawe Park Rd. W.',
      city: 'London',
      province: 'ON',
      postalCode: 'N6G 0E1',
      country: 'CANADA',
      lat: 43.022747,
      lng: -81.297538,
    },
    hours: {
      monday: CMS.hoursMonday,
      tuesday: CMS.hoursTuesday,
      wednesday: CMS.hoursWednesday,
      thursday: CMS.hoursThursday,
      friday: CMS.hoursFriday,
      saturday: CMS.hoursSaturday,
      sunday: CMS.hoursSunday,
    },
    offers: {
      general: CMS.offerDate,
    },
  };

  return data;
}
