import React from 'react';
import {LazyLoad} from './LazyLoad';

interface Props {
  src: string
  alt?: string
  onClick?: () => void
  style?: object
  borderRadius?: string | number
}

export const Image = (props: Props) => {
  return (
    <LazyLoad>
      <picture>
        <source srcSet={props.src} /> 
        <img alt={props.alt} src={props.src} style={{ ...props.style, borderRadius: props.borderRadius || 0 }} onClick={() => props.onClick && props.onClick()} />
      </picture>
    </LazyLoad>
  );
}