export function deviceSize(){
    const windowWidth = window.innerWidth;
    if(windowWidth < 700) return 'mobile';
    if(windowWidth < 1100) return 'tablet';
    return 'desktop';
}

export const isLocalhost = window.location.href.indexOf("localhost") > -1;

export function scrollTo(scrollID){
    if (!scrollID) {
        return
    }
    
    // wait a sec so the scroll to top method on page change doesnt run after 
    setTimeout(() => {
        document.getElementById(`ScrollTo${scrollID}`).scrollIntoView({ behavior: "smooth", block: 'start'  });
    }, 200);
}

export function localStorageSave(key, value){
    console.log('Setting local storage', value);
    value = JSON.stringify(value);
    window.localStorage.setItem(key, value);
}

export function localStorageGet(key){
    const localData = window.localStorage.getItem(key);
    console.log('Getting local storage',localData);
    return JSON.parse(localData);
}