import React from 'react';
import * as styles from './styles';

import { Link as RouterLink } from 'react-router-dom';

export const Link = (props) => {
  const shouldOpenNewTab = props.newTab ? '_blank' : '';
  if(props.external){
      return (
        <a data-calltrk-noswap={props['data-calltrk-noswap']} key={props.key} style={styles.link} target={shouldOpenNewTab} href={props.href || '/'}>
          {props.children}
        </a>
      );
    }else{
      return (
        <RouterLink target={shouldOpenNewTab} data-calltrk-noswap={props['data-calltrk-noswap']} style={styles.link} to={props.href || '/'}>
          {props.children}
        </RouterLink>
      );
    }   
}