import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import getNavItems from '../../data/navItems';
import { deviceSize } from '../../helpers/device';

import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router';
import { Link } from '../Link';
import stylesFunction from './styles';

export const SubNav = () => {
  const location = useLocation();

  const [hoveringId, setHoveringId] = useState(-1);
  const [currentIntervalTick, setCurrentIntervalTick] = useState(-1);

  useEffect(() => {
    let count = -1
    const interval = setInterval(() => {
      count++
      setCurrentIntervalTick(count);
    }, 100);
    return () => clearInterval(interval);
  }, [hoveringId])

  function setHoverId(index){
    setHoveringId(index);
    setCurrentIntervalTick(-1);
  }

  function toggleMobileMenu(){
    if(hoveringId !== 'mobile'){
      setHoverId('mobile');
    }else{
      setHoverId(-1);
    }
  }

  const currentLocation = location.pathname;
  const pathSections = currentLocation.split('/');
  const currentSection = pathSections.length && pathSections[1];

  const styles = stylesFunction();
  const navItems = getNavItems();
  const device = deviceSize();

  let currentNavItem = null;
  navItems.forEach((item) => {
    (item.subMenu || []).forEach((subItem) => {
      const matchesMainLink= item.url.toLowerCase() === "/"+currentSection.toLowerCase();
      const matchesSubLink = subItem.url.toLowerCase() === "/"+currentSection.toLowerCase();
      if(matchesMainLink || matchesSubLink){
        currentNavItem = item;
      }
    });
  });

  const currentNavSubItems = currentNavItem && currentNavItem.subMenu;
  
  if(device === 'desktop' ) return (<React.Fragment />);
  if(!currentNavSubItems) return (<React.Fragment />);

  return (
    <>
      <div style={styles.navHeight}>
        <div style={{...styles.navBarContainer, ...styles.navHeight}}>

          {hoveringId !== 'mobile' && <FontAwesomeIcon style={styles.bars} icon={faBars} color={"white"} onClick={() => toggleMobileMenu()} />}
          {hoveringId === 'mobile' && <FontAwesomeIcon style={{...styles.bars, paddingLeft: 2, paddingRight: 3}} icon={faTimes} color={"white"} onClick={() => toggleMobileMenu()} />}

          <div style={styles.mobileLinkHolder}>

            {currentNavSubItems.map((link, index) => {
              return (<Link href={link.url} key={`a-${index}`} >
                <div onClick={() => setHoverId(-1)} style={{ ...styles.mobileLink, ...((currentIntervalTick > index - 1 && hoveringId === 'mobile') ? { height: 'auto', transform: `translate(0px, 0px)`, paddingTop: 10, paddingBottom: 10, opacity: 1, zIndex: 1 } : {}) }}>
                  {link.overide || link.name}
                </div>
              </Link>);
            })}
          </div>

          <div style={styles.subTxt}>
            {currentNavItem.nameMobile}
          </div>

        </div>
      </div>
    </>
  );
}