export const theme = {
  colors: {
    purple: '#4F3970',
    darkPurple: '#4d2ac1',
    black: 'black',
    blackSubdued: 'rgba(0,0,0,0.6)',
    gray: 'grey',
    darkGrey: '#585858',
    green: 'green',
    lightGrey: '#C1C1C1',
    orange: '#ca8705',
    whiteSubdued: 'rgba(255,255,255,0.6)',
    red: 'red',
    white: '#ffffff',
    blue: '#255E8F',
    gold: '#0a3580',
    dentoPlanDarkGreen: '#58635e',
    dentoPlanLightGreen: '#C6D552',
  },
  weights: {
    normal: 'normal',
    medium: 'medium',
    bold: 'bold',
  },
  sizing: {
    xxs: 1,
    xs: 5,
    sm: 10,
    md: 15,
    lg: 50,
    xl: 75,
    xxl: 100,
  },
  textSizes: {
    xxs: 1,
    xs: 5,
    sm: 10,
    md: 15,
    lg: 22,
    xl: 30,
    xxl: 80,
  },
  mobileTextSizes: {
    xxs: 1,
    xs: 5,
    sm: 10,
    md: 15,
    lg: 50,
    xl: 75,
    xxl: 100,
  },
  screenSizes: {
    mobile: 800,
    tablet: 1000,
  }
}