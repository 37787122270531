import React, { Component, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useSearchParams } from 'react-router-dom';

// This component will scroll the app to the top when the route changes

export const ScrollToTop = () => {
  const location = useLocation()
  const [previousLocation, setPreviousLocation] = useState(location)

  let [searchParams] = useSearchParams();
  const scrollToElement = searchParams.get('scrollTo');

  useEffect(() => {

    const searchParamWasJustRemoved = previousLocation.search !== location.search && previousLocation.pathname === location.pathname

    if (!scrollToElement && !searchParamWasJustRemoved) {
      window.scrollTo(0, 0)
    }
    setPreviousLocation(location)
  }, [location])

  return <></>
}
